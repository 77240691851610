@keyframes borderFlashMoveableLine {
    0% {
        border: 1.5px solid rgb(132, 38, 223, 0.5);
        background: rgb(132, 38, 223, 0.5);
    }
    30% {
        border: 1.5px solid rgb(132, 38, 223, 1);
        background: rgb(132, 38, 223, 1);
    }
    60% {
        border: 1.5px solid rgb(132, 38, 223, 1);
        background: rgb(132, 38, 223, 1);
    }
    100% {
        border: 1.5px solid rgb(132, 38, 223, 0.5);
        background: rgb(132, 38, 223, 0.5);
    }
}

.moveable-line {
    /* border: 1px solid rgb(132, 38, 223, 1) !important;
    background: rgb(132, 38, 223, 1) !important; */

    animation-name: borderFlashMoveableLine;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

.moveable-control {
    animation-name: backgroundFlash;
    animation-duration: 1s;
    animation-iteration-count: infinite;

    width: 15px !important;
    height: 15px !important;
    margin-top: -7.5px !important;
    margin-left: -7.5px !important;
    border-radius: 0px !important;
    border: none !important;
}

.moveable-control.moveable-origin {
    border: 2px solid white !important;
}
