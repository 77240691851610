body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@font-face {
    font-family: "Digit";
    font-style: normal;
    font-weight: normal;
    src:
        local("Digit"),
        url("../Digit.woff") format("woff");
}

/* #resizable {
  
  z-index: 1000000 !important;

} */

.ui-resizable-helper {
    /* border: 1px solid blue;
  background-color: none;
  opacity: 1; */
    z-index: 1000000 !important;
}

/* TODO: come up with a better fix for mui dialog content 0 opacity problem (where it stays in the dom) */
/* this temporary css fix came from: https://github.com/mui/material-ui/issues/32286 */
/* this css here fixs the issue where rich text editor stays in dom and is clickable but has opacity: 0 after dialog is closed */
div:has(div[style*="opacity: 0"]) {
    pointer-events: none;
}

/* this is so when transform is off on hotspot it still retains original styles */
.vt-html-hotspot-viewer > div{
    /* border: 3px solid green; */
    position: unset !important;
    /* background: "red !im"; */
}

input:-internal-autofill-selected {
    background-color: none !important;
}



/* .ui-resizable-ghost { */
/* opacity:0 !important; */
/* z-index: 1000000 !important; */
/* } */
